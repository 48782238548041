/* eslint-disable */
import React from "react"
import Layout from "../components/Layout"
import LoadRecaptcha from "../components/LoadRecaptcha"
import Banner from "../components/Banner"
import Faq from "../components/Home/Faq"
import { graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Helmet } from "react-helmet"

export default ({ data }) => (
  <Layout>
  <Helmet>
    <title>Rivet | FAQ</title>
  </Helmet>
      <Faq />
  </Layout>
)
export const query = graphql`
  query {
    defaultBcg: file(relativePath: { eq: "defaultBcg.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
