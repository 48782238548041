/* eslint-disable */
import React from "react"
import Title from "../Title"
import styles from "../../css/faq.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
// import img from "../../images/defaultBcg.jpeg"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Faq = () => {
  // TODO: Greg review language below DONE
  return (
    <section className={styles.faq}>
      <Title title="Frequently Asked Questions" className={styles.faqTitle}/>
      <div className={styles.center}>
        <article className={styles.faqText}>
          <ul className={styles.faqList}>
               <h4>Q: Our Values</h4>
               <details><summary>What is our relationship to the Ethereum ecosystem?</summary>
               <li>OpenRelay Blockchain Solutions has been contributing to the Ethereum ecosystem since 2017! Since then, we've built OpenRelay—our Ox order book infrastructure—developer tools like Massive and Fling!, and Rivet’s breakthrough streaming replication mechanism conceived and brought to life as the Ethercattle Initiative (funded in part by grants from the 0x Foundation). We've been proud Ethereans from the word go, and we'll be here or bust until moondom come!</li></details>
               <details><summary>Economically sustainable? What do you mean?</summary>
               <li>Traditional Geth nodes impose a technical and operational cost that does not scale—especially because the specialized skills needed to do so are of extremely limited supply. Our approach avoids that cost, enabling us to offer extremely competitive pricing while pursuing a realistic path to long-term revenue-positive operations.</li></details>
               <details><summary>Privacy—what can you do with my request data?</summary>
               <li>Request data contains substantially more information than what appears on the public ledger. Users' wallet addresses, token balances, web3 browsing activity along with PII that can be connected back to web2 services like Facebook. If you or your users' idiosyncratic usernames, email addresses and even IP addresses can be tied to activity on Facebook or Google, web3 might become just another vector for information-miners, advertizers and oppressive regimes to exploit. That's why we've created an armored privacy policy that we promise to stand over on principle. We will never sell or willingly share your data with 3rd parties—full stop. And if we're coerced by operation of law to comply with a lawful request for your information (we won't roll over), we've taken every measure we can to minimize the quantity and scope of that information about you and your users. No competing services even come close.</li></details>
               <details><summary>Why open source? Won't someone else just copy your hard work?</summary>
               <li>TLDR: we hope so! Open source is at the core of who we are—it's not only the right thing to do for the community, it enables us to guarantee that if you build on our technology, you won't be dependent on our continued existence to sustain your business. And hey—if competition raises the bar, we're happy to race you to the top.</li></details>
               <h4>Q: Our Plans</h4>
               <details><summary>What is the BUIDL Plan?</summary>
               <li>BUIDL is our introductory service—everyone who signs up with an email address starts with BUIDL. What do you get with it? 3,000,000 requests per month and you will not be asked for billing information, however, after you have consumed 3,000,000 requests your API keys will be disabled for the remainder of the calendar month.</li></details>
               <details><summary>What is the SCAEL Plan?</summary>
               <li>SCAEL is our paid offering.  SCAEL starts with all the same benefits of the BUIDL plan and extends them. Not only do you still get 3,000,000 free requests every month, you can use as many requests as you need beyond that at an industry-beating price: $1 for 100k requests. That's it! Oh, and you can downgrade to BUIDL whenever you want, and you will only ever be billed for what you actually use. No minimums, long-term contracts, negotiations, or BS. To upgrade, log into your dashboard, click or tap the Upgrade to SCAEL button, and add a valid payment card. That's all there is to it! And like BUIDL, there are no commitments. We believe you'll love Rivet, but if you don't, you're free to leave us anytime.</li>
               <li>See how much you'll pay with our handy calculator—<AniLink paintDrip to="/requestimator" hex="#070707">the Requestimator!</AniLink> ««(Linked there!)</li>
               </details>
               <details><summary>What is the Bolt Plan?</summary>
               <li>Bolt is a subscription-based plan that gives you access to our Bolt service—an index of transaction data inspired by etherscan’s API, but without the anemic call limits. You also get 2.5M requests each time your monthly subscription is billed. Additionally, you will recieve time-limited access to Bolt when you buy request credits using crypto.</li>
               </details>
               <details><summary>How do I sign up?</summary>
               <li>You can get started by tapping or clicking <AniLink paintDrip to="/buidl" hex="#070707">»» BUIDL ««</AniLink> (Yep, that's a link. Tap or click that one. Between the arrows. To the left of this humorously long parenthetical! It has a cool transition animation too. The link, not the parenthetical. The animation is worth the trip, we promise. It's like—WOOOOSH! Trust us you'll have fun. Check it out and sign up......................Ok bye!)</li></details>
               <details><summary>How do I upgrade my plan to SCAEL?</summary>
               <li>In the upper-right corner of the dashboard, there's a button that says "Upgrade to SCAEL!" Tap it, add a valid payment card, and you're good to go!</li></details>
               <h4>Q: API Keys</h4>
               <details><summary>What does "API Key Unavailable" mean and why am I seeing it?</summary>
               <li>You will see this error when you've run out of requests for the month on BUIDL or you are out of request credits. To resolve the issue, either wait until the beginning of the next month (when you receive 3M more requests under the BUIDL plan), upgrade to SCAEL, or purchase some requests using crypto.</li>
               </details>
               <details><summary>Why would I need to generate multiple API keys?</summary>
               <li>You can use different API keys to track request usage of different applications or different environments. Eventually we will give you the ability to constrain keys in different ways—such as limiting them to interactions with specific contracts, or only accepting requests from certain IP ranges.</li></details>
               <details><summary>What is the deal with the two different key formats?</summary>
               <li>We support two different encodings for your keys—hexadecimal, and Bip39 mnemonic encoding. The hex keys are shorter and slightly easier on bandwidth. The mnemonic keys are easier to remember and talk about. They are two different encodings for the same key, so which one you use is up to you.</li></details>
               <details><summary>Can I toggle the format at will? What’s the impact to my application?</summary>
               <li>The toggle in the dashboard is just for you to see the keys in your preferred format. You can use either format in your application, or even use one format in one application while using another format for the same key in another application.</li></details>
               <h4>Q: Helpful Information for New Developers</h4>
               <details><summary>Examples of Web3 Libraries</summary>
               <li>Any Web3 library can connect to Rivet. Some examples include:
               <li>Web3.js: <a href="https://github.com/ethereum/web3.js/" className={styles.instructions}>Github</a></li>
               <li>Ethers.js: <a href="https://github.com/ethers-io/ethers.js/" className={styles.instructions}>Github</a></li>
               <li>Web3.py: <a href="https://web3py.readthedocs.io/en/stable/" className={styles.instructions}>ReadTheDocs</a></li></li></details>
               <details><summary>List of Tools and Documentation</summary>
               <li>Each of the web3 libraries listed above has its own documentation. If you are interested in interacting directly with the RPC protocol, the full protocol is <a href="https://github.com/ethereum/wiki/wiki/JSON-RPC#json-rpc-api-reference" className={styles.instructions}>documented here</a>.</li></details>
               <h4>Q: Dashboard</h4>
               <details><summary>How do I change my payment method?</summary>
               <li>Under "My Account", click or tap "Change Payment Method" to reveal the super-simple controls. We didn't add anything fancy, so if you've used Amazon, you know the drill.</li>
               <li>(We'll also notify you via email when the update has successfully been applied.)</li></details>
               <details><summary>What billing service do you use?</summary>
               <li>Stripe! (We also plan to support crypto payments as soon as we can—details to follow, so stay tuned and watch this space!</li></details>
               <details><summary>How do I update my email address?</summary>
               <li>Under "My Account" tap or click "Account Email" to reveal a set of super-simple controls.</li>
               <li>You will receive an email notification when the information has been successfully changed</li></details>
               <details><summary>How do I update my Password?</summary>
               <li>Click on Update Password line in the “My Account” section and you will see a box expand which will allow you to change your password</li>
               <li>You will receive an email notification when the information has been successfully changed.</li>
               <li>Note that for your security, this update will require you to re-authenticate to access the dashboard.</li></details>
               <details><summary>What's up with the Plan Details box?</summary>
               <li>Active Rate: This is how much you pay for every 100,000 requests</li>
               <li>Usage: Shows your current request usage for all the keys in your account</li>
               <li>Tier Max: Shows the number of requests until the next cost level</li>
               <li>Days left in Cycle: Shows the number of days left in this billing cycle before the month is closed and billed.</li>
               <li>Metered Cost: The cost of transactions on your plan</li></details>
               <details><summary>Where is Kovan?</summary>
               <li>Rivet is based on the <a href="https://ether-cattle-initiative.readthedocs.io/en/latest/" target="_blank">Ether Cattle Initiative</a>, which is an open source fork of Geth. Kovan is only supported by the Parity Ethereum client. We&apos;re working on how to add Kovan support, but Kovan is not supported at this time. In the meantime, we would encourage you to consider using the Goerli testnet, which is a newer Proof-of-Authority supported by most Ethereum clients.</li>
               </details>
               <details><summary>My question isn’t answered here. Where can I get help?</summary>
               <li>We have integrated chat on the <a href="https://rivet.cloud">Rivet Homepage</a></li>
               <li>Find us on Telegram <a href="https://t.me/Rivet_ETH">t.me/Rivet_ETH</a></li>
               <li>Chat with us on Twitter <a href="https://twitter.com/R_I_V_E_T">@R_I_V_E_T</a></li>
               </details>
               <details><summary>How do I contact support?</summary>
               <li>You can contact us via email at <a href="mailto:support@rivet.cloud" target="_blank">support@rivet.cloud</a>.</li>
               </details>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default Faq
